<template>
  <div>
    <h1 v-if="user.temPin" class="title is-4">Alterar PIN</h1>
    <h1 v-else class="title is-4">Cadastrar Novo PIN</h1>

    <form @keyup.enter="changePin()">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Senha Atual"
          v-if="user.temPin"
          :type="campoInvalido('currentPassword', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('currentPassword', 'data') ? 'Senha atual é um campo obrigatório' : ''
          "
        >
          <b-input
            password-reveal
            placeholder="PIN Atual"
            type="password"
            v-model="data.currentPassword"
          />
        </b-field>

        <b-field
          expanded
          password-reveal
          label="Novo PIN"
          :type="campoInvalido('newPin', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('newPin', 'data') ? 'Novo PIN é um campo obrigatório' : ''"
        >
          <b-input
            password-reveal
            placeholder="Novo PIN"
            type="password"
            v-model="$v.data.newPin.$model"
          />
        </b-field>

        <b-field
          expanded
          password-reveal
          label="Confirmar Novo PIN"
          :type="campoInvalido('confirmNewPin', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('confirmNewPin', 'data') ? 'Os novos PINs devem ser idênticos' : ''
          "
        >
          <b-input
            password-reveal
            placeholder="Confirmar Novo PIN"
            type="password"
            v-model="$v.data.confirmNewPin.$model"
          />
        </b-field>

        <b-field>
          <b-button
            class="button is-primary is-fullwidth change-pin-button"
            :loading="loading"
            @click.prevent.stop="changePin()"
          >
            {{ !user.temPin ? 'Salvar PIN' : 'Alterar PIN' }}
          </b-button>
        </b-field>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';

import formValidation from '@/mixins/formulario';
import { passwordRules } from '@/utils/data';

export default {
  name: 'ChangePin',
  mixins: [formValidation],
  data() {
    const data = {
      confirmNewPin: '',
      currentPassword: '',
      newPin: '',
    };

    return {
      data,
      loading: false,
      rules: [...passwordRules],
    };
  },
  validations: {
    data: {
      confirmNewPin: {
        required,
        sameAsPassword: sameAs('newPin'),
      },
      currentPassword: {
        required: requiredIf(function userHasPin() {
          return this.user.temPin;
        }),
      },
      newPin: { required },
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.data.$anyDirty) {
      const sair = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(sair);
    }
    return next();
  },
  computed: {
    ...mapGetters({ user: 'getUser', logged: 'getLogged' }),
  },
  methods: {
    ...mapActions(['updateUser']),
    cleanPinFields() {
      this.data.confirmNewPin = '';
      this.data.currentPassword = '';
      this.data.newPin = '';
      this.$v.data.$reset();
    },
    async changePin() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading = true;
      try {
        const hasPin = this.user.temPin;
        await this.updateUser(this.data);
        this.$alerta(hasPin ? 'PIN alterado' : 'PIN cadastrado', 'is-success');
        this.cleanPinFields();
      } catch (error) {
        this.$alerta('Senha atual incorreta', 'is-danger');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change-pin-button {
  height: 100px;
}

@media (max-width: 769px) {
  .change-pin-button {
    height: unset;
    margin-top: 0.75rem;
  }
}
</style>
